// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--lU7l1";
export var footer__bottom = "footer-module--footer__bottom--WOJmt";
export var footer__column = "footer-module--footer__column--lqQ7x";
export var footer__copyright = "footer-module--footer__copyright--8Lyb-";
export var footer__link = "footer-module--footer__link--OQpJa";
export var footer__links = "footer-module--footer__links--Gzjna";
export var footer__scrollToTop = "footer-module--footer__scrollToTop--PkQD3";
export var footer__social = "footer-module--footer__social--A7sgg";
export var footer__socialLink = "footer-module--footer__socialLink--1ubfD";
export var footer__title = "footer-module--footer__title--NmIn6";
export var footer__top = "footer-module--footer__top--KGV+4";
export var footer__wrapper = "footer-module--footer__wrapper--3L4Gr";