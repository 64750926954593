import * as React from 'react';
import { Grid } from 'react-flexbox-grid';
import 'bootstrap/dist/css/bootstrap.css';

import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import { Props } from './index.interface';
import { app, app__page} from 'layouts/index.module.scss';

export default ({ children }: Props) => (
    <div className={app}>
        <Grid>
            <Header />
        </Grid>
        <div className={app__page}>
            {children}
        </div>
        <Footer />
    </div>
);