
import * as React from 'react';
import { Grid } from 'react-flexbox-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitbucket, faLinkedin, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';

import {
    footer,
    footer__wrapper,
    footer__top,
    footer__bottom,
} from './footer.module.scss';

const Footer = (): JSX.Element => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={footer}>
            <Grid>
                <div className={footer__wrapper}>
                    <div className={footer__top}>
                    </div>
                    <div className={footer__bottom}>
                    </div>
                </div>
            </Grid>
        </footer>
    );
};

export default Footer;