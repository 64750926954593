import * as React from 'react';
import Link from 'gatsby-link';
import { Collapse,  Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';

import { header , navbar } from './header.module.scss';

const Header = () => {
    return (
        <header className={header}>
            <Navbar color="white" light expand="md" className={navbar} fixed="fixed">
                <Link className="navbar-brand" to="/">Kieran White</Link>
            </Navbar>
        </header>
    );
}

export default Header;
